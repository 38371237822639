@import "../../assets/styles/variables";

.rbWrapper {
  padding: 80px 1rem;

  .customerDetail {
    padding: 2rem 1rem;
    border-radius: 1.25rem;
    background: $secondary;
  }

  @include breakpoint(desktop) {
    .customerDetail {
      padding: 2rem;
      width: 80%;
      margin: auto;
    }
  }
}
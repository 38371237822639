@import "../../assets/styles/variables";

.Products__buttonType {
  display: flex;

  button {
    white-space: nowrap;
  }
}

.products {
  position: relative;
  padding-bottom: 2em;
  padding-top: 30px;

  @include breakpoint(desktop) {
    padding-top: 20px;
  }

  .header {

    @include breakpoint(untilTablet) {
      margin-top: -3rem;
    }

    .container-fluid {
      padding: 0;
      height: 190px;
      margin: auto;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
      }

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }

      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 4;
        color: white;
        font-size: 28px;
        padding: 0 20px 10px;
        font-weight: 500;
        letter-spacing: 0.1rem;
        font-family: 'Noto Serif JP', serif;
        text-align: center;

        @include breakpoint(untilTablet) {
          width: 100%;
        }

        &:after {
          content: "";
          width: 100px;
          height: 2px;
          background: #FFF;
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          bottom: 0;
          z-index: 50;
        }
      }
    }
  }

  .loadMore {
    display: flex;
    justify-content: center;

    .btn {
      border-radius: 20px;
      padding: 4px 40px !important;
      text-transform: uppercase;
      color: $primary;
      margin-top: 2rem;
    }
  }

  >.wrap {

    @include breakpoint(untilTablet) {
      margin-top: 1rem;
    }
  }

  .productsWrapper,
  .skeletonWrapper {
    @include breakpoint(untilTablet) {
      width: 100%;
    }
  }


  .skeletonWrapper {
    padding: 2em 0;
    max-width: 1200px;
    margin: auto;

    .skeletonShop {
      display: grid;
      grid-template-columns: 30% 70%;
      gap: 20px;
    }


    .lang {

      display: flex;
      justify-content: flex-end;
      margin-left: auto;

      @include breakpoint(untilTablet) {
        margin-right: auto;
        margin-left: 0;
      }
    }

    @include breakpoint(untilTablet) {
      .button {
        width: 40%;
        display: flex;
        margin: auto;
      }
    }
  }

  .titlePage {
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: $primary;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 2rem;

    @include breakpoint(untilTablet) {
      font-size: 1.25em;
    }
  }

  .categoryTabs {
    nav {
      display: flex;
      padding: 2rem 0 1rem;
      align-items: center;
      flex-wrap: nowrap;
      overflow: auto;

      .categoryTabs__item {
        font-size: 1.25rem;
        margin-right: 1rem;
        padding: .5rem 1rem;
        border-bottom: 2px solid lightgray;
        white-space: nowrap;

        &.active {
          font-weight: bold;
          border-bottom: 2px solid $primary;
        }
      }
    }
  }

  .productsOption {
    padding: 2rem 0;
    border-bottom: 1px solid lightgray;
    margin-bottom: 3rem;

    .searchCount {
      font-weight: bold;
      font-size: 1.15rem;

      span {
        font-size: 1.75rem;
        line-height: 1em;
      }

      .count {
        font-size: 1.5rem;
      }

    }

    .optionWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: 1200px;
      margin: auto;
      gap: 4rem;


      >div:last-child {
        justify-content: end;
      }

      @include breakpoint(untilTablet) {
        flex-direction: column;
        grid-gap: 1em;
        align-items: start;

        >div:first-child,
        >div:last-child {
          width: 100%;
        }

        .sort {
          flex-wrap: wrap;

          .btn {
            width: 10em;
            margin: 0.25em;
          }
        }

        >div:last-child {
          justify-content: flex-start;
        }
      }
    }

    button,
    .form-select {
      margin: 0.25em;
      padding: .5rem 1rem;
    }

    .btn-primary {
      background: $primary;
    }

    .btn-white:hover {
      background: $primary;
      color: white;
      transition: all .2s ease;
      border-color: transparent;
      border-color: #42a3fc;
    }

    .form-select {
      padding-right: 2em;
      border: 1px solid rgb(127, 135, 136);
      border-radius: 5px;
      min-width: 80px;
    }

    .sort {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include breakpoint(desktop) {
        justify-content: center;
      }

      .text {
        white-space: nowrap;
        margin-right: 1rem;
      }
    }
  }

  .productItems {
    margin: auto;
    max-width: 1200px;

    .col-12 {
      &:last-child {
        .item.shopping {

          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

  }
}

.sliders {
  background-color: white;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem .75rem;
    gap: 1rem;
  }

  .item {
    width: 100%;

    @include breakpoint(desktop) {
      width: 32%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
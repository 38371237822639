@media (max-width: 767.98px) {

  .wrap,
  .wrap-min {
    padding: 0 .75rem;
  }
}

.of-cover {
  object-fit: cover;
  object-position: center;
}

.f-11px {
  font-size: 11px;
}

.position-sticky {
  position: sticky;
  top: 0;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 0;
}

.modal-footer {
  border-top: none !important;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.top {
  position: fixed;
  bottom: 80px;
  right: 40px;
  z-index: 100;
  cursor: pointer;

  img {
    width: 60px;
    height: 60px;
  }

  @include breakpoint(untilTablet) {
    bottom: 50px;
    right: 20px;

    img {
      width: 40px;
      height: 40px;
    }
  }
}

.btn {
  border-radius: 0;
}

#breadcrumbs {
  font-size: 14px;
  padding: 1em 0;
  max-width: 1200px;
  margin: auto;

  a {
    text-decoration: underline;
    color: #666;
    margin-right: .5rem;
  }

  span {
    margin-right: .5rem;
    color: #666;
  }
}

dt {
  font-weight: normal !important;
}

svg {
  width: 1em;
  height: 1em;
}

.react-calendar {
  width: 200px;
  position: absolute;

  &.hide {
    display: none;
  }
}

.react-calendar__navigation button {
  min-width: 24px;
}

.react-calendar__tile {
  line-height: 10px;
}

.form-control[readonly] {
  background-color: white;
  cursor: pointer;
}

.btn {
  border-radius: 5px;

  &.btn-white {
    padding: 0.5em;
    background: #fff;
    border: 1px solid #7f8788;
    border-radius: 5px;
    fill: #7f8788;
    text-align: center;
    cursor: pointer;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}


.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 16px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;

  &.active {
    display: block;
  }

}

.hidden {
  display: none;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1
}
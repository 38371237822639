/* CSS Document */

#feature {
    padding: 0;
}

#course {
    padding: 0;
}

#course+#search #searchMenuSelect li:nth-of-type(2),
#course+#search #searchMenuSelect li:nth-of-type(3) {
    display: none;
}

#spot {
    padding: 0;
}

#experience {
    padding-top: 0;
}

#experience nav {
    padding-top: 0;
}

#event {
    padding: 0;
}

#gourmet {
    padding: 0;
}

#souvenir {
    padding: 0;
}

#stay {
    padding-top: 0;
}

#stay nav {
    padding-top: 0;
}

#theme {
    padding-top: 0;
}

#theme header {
    margin-bottom: 2em;
    padding: 0;
}

#theme section {
    padding: 0;
}

#theme h3 {
    color: #0080d2;
    text-align: left;
}

#theme h3+div {
    margin-top: 1em;
    line-height: 1.4em;
    order: 1;
}

#learning {
    padding: 0;
}

#hotel {
    padding: 0;
}

#institution {
    padding: 0;
}

#institution+#search #searchSetting {
    background: #fef2e4;
}

#institution+#search #searchSetting .select {
    background: #ef8d32;
}

#flowering {
    text-align: center;
    padding-top: 0;
}

#flowering figure {
    margin-bottom: 1em;
}

#flowering figure .thumb {
    padding-top: 50%;
}

#flowering p+p {
    margin-top: 2em;
}

#flowering+#search .spotGrid {}

#flowering+#search .spotGrid dd {
    /*height: auto;*/
}

#flowering+#search .spotGrid dd:nth-of-type(3),
#flowering+#search .spotGrid dd:nth-of-type(3) {
    display: inherit;
    height: auto;
    font-size: 1em;
    overflow: visible;
}

#flowering+#search .spotGrid dd:nth-of-type(4),
#flowering+#search .spotGrid dd:nth-of-type(4) {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#pickup {
    position: relative;
    padding-top: 0;
    overflow: hidden;
}

#pickup dl {
    position: relative;
}

#pickup dl {}

#pickup dt {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 4em;
    padding: 0.8em;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    z-index: 1;
}

#pickup dd {}

#pickup dd a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
}

#pickup .wrap {
    width: 100%;
}

#pickup .slick-list {
    margin: 0 -1px;
}

#pickup .slick-slide {
    padding: 0 1px;
}

#pickup .slick-arrow {
    position: absolute;
    top: 50%;
    margin: -2em 0;
}

#pickup .slick-prev {
    left: 1em;
}

#pickup .slick-next {
    right: 1em;
}

#search {
    padding-top: 0;
}

#searchMenuTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #0080d2;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
}

#searchMenuTitle i {
    margin-right: 0.5em;
}

#searchMenuSelect {}

#searchMenuSelect ul {
    display: flex;
}

#searchMenuSelect li {
    display: flex;
}

#searchMenuSelect li:last-child {
    align-items: center;
}

#searchMenuSelect a {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff;
    border: 1px solid #7f8788;
    border-radius: 5px;
    padding: 0.6em 1em;
    padding-right: 2em;
    color: inherit;
    text-decoration: none;
    line-height: 1.4em;
    cursor: pointer;
    transition: none;
}

#searchMenuSelect a i {
    position: absolute;
    top: calc(50% - 0.6em);
    right: 0.5em;
    width: 1.2em;
    height: 1.2em;
    background: #989d9e;
    fill: #fff;
    border-radius: 100%;
    transform: rotate(90deg);
}

#searchMenuSelect a svg {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
}

#searchMenuSelect input {
    padding: 0.6em 1em;
    background: #e6e7e7;
    border: none;
    border-radius: 5px;
    line-height: 1.4em;
}

#searchMenuSelect button {
    background: #7f8788;
}

#searchMenuSelect .select {
    background: #0080d2;
    border-color: transparent;
    color: #fff;
    fill: currentColor;
}

#searchMenuSelect .select i {
    background: currentColor;
    fill: #0080d2;
    transform: rotate(-90deg);
}

#searchMenuDetail {
    position: relative;
}

#searchMenuDetail ul {
    display: flex;
}

#searchMenuDetail li {
    margin-bottom: 1em;
}

#searchMenuDetail li ul {
    flex-direction: column;
    margin-top: 1em;
}

#searchMenuDetail li li {
    width: 100%;
    margin-left: 1em !important;
}

#searchMenuDetail button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.8em 2em;
    border-radius: 2em;
    color: #fff;
    fill: #fff;
    font-weight: normal;
    text-align: center;
}

#searchMenuDetail button i {
    display: flex;
    align-items: center;
    margin-right: 0.5em;
}

#searchMenuDetail .buttonSet {
    position: sticky;
    bottom: 0;
    align-items: center;
    margin-top: 2em;
}

#searchMenuDetail .doSearch {
    background: #0080d2;
}

#searchMenuDetail .doClear {
    background: #7f8788;
}

#searchMenuArea {}

#searchMenuArea figure {
    position: relative;
}

#searchMenuArea figure:before {
    content: "";
    display: block;
    padding-top: 61.46%;
}

#searchMenuArea figure svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#searchMenuArea li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#searchMenuArea [for="areaL1"],
#searchMenuArea [for="area1"] {
    margin-bottom: 0.5em;
    padding: 0.5em;
    background: #fca399;
    border-radius: 5px;
}

#searchMenuArea [for="areaL2"],
#searchMenuArea [for="area2"] {
    margin-bottom: 0.5em;
    padding: 0.5em;
    background: #76cc88;
    border-radius: 5px;
}

#searchMenuArea [for="areaL3"],
#searchMenuArea [for="area3"] {
    margin-bottom: 0.5em;
    padding: 0.5em;
    background: #ffcd53;
    border-radius: 5px;
}

#searchMenuArea [for="areaL4"],
#searchMenuArea [for="area4"] {
    margin-bottom: 0.5em;
    padding: 0.5em;
    background: #6fced4;
    border-radius: 5px;
}

#searchMenuDate {}

#searchMenuDate table {
    width: 100%;
}

#searchMenuDate caption {
    position: relative;
    margin: 0.8em;
    margin-top: 0;
    color: #0080d2;
    font-weight: bold;
    text-align: center;
}

#searchMenuDate caption span {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 0.8em;
}

#searchMenuDate tbody {
    font-weight: bold;
}

#searchMenuDate td {
    padding: 0.3em 0;
    border: 1px solid #fff;
    text-align: center;
}

#searchMenuDate td:last-child {
    background: #d3e7f2;
    color: #2991c8;
}

#searchMenuDate td:first-child,
#searchMenuDate td.holiday {
    background: #fae3e1;
    color: #b4000b;
}

#searchMenuDate .check {
    background: #0080d2 !important;
    color: #fff !important;
}

#searchMenuDate [data-ymd^="20"] {
    cursor: pointer;
}

#searchMenuDate .slick-slider {
    display: flex;
    justify-content: space-between;
}

#searchMenuDate .slick-arrow {
    position: relative;
    align-self: center;
    width: 2em;
    height: 4em;
}

#searchCurrent {
    margin-top: 1em;
}

#searchCurrent ul {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5em;
    font-size: 0.8em;
}

#searchCurrent li {
    margin: 0.5em;
}

#searchCurrent li a,
#searchCurrent li button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.4em;
    padding: 0.5em 0.8em;
}

#searchCurrent li a {
    background: #cedaf3;
    border-radius: 4px;
    color: #0080d2;
    text-decoration: none;
}

#searchCurrent li i {
    margin-left: 0.5em;
}

#searchCurrent li svg {
    transform: scale(0.8);
}

#searchCurrent li button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8em;
    background: #fff;
    border: 1px solid #7f8788;
    border-radius: 4px;
    color: inherit;
    font-weight: normal;
}

#searchSetting {
    margin: 2em 0;
    background: #eff1f6;
}

#searchSetting .wrap {
    display: flex;
}

#searchCount {}

#searchCount span {
    margin-right: 0.2em;
    font-size: 2em;
    line-height: 1em;
}

#searchSort {}

#searchSort dl {
    display: flex;
    flex-wrap: wrap;
    margin: -0.25em;
    font-size: 0.8em;
}

#searchSort dt {
    display: none;
}

#searchSort dd {
    width: 10em;
    margin: 0.25em;
    padding: 0.5em;
    background: #fff;
    border: 1px solid #7f8788;
    border-radius: 5px;
    fill: #7f8788;
    text-align: center;
    cursor: pointer;
}

#searchSort .select {
    background: #0080d2;
    border-color: transparent;
    color: #fff;
    fill: currentColor;
}

#searchSort [lsc-lt="Order"] {
    display: none;
}

#searchSort [lsc-lt="dummy"] {
    display: inherit;
}

#searchDisplay {}

#searchDisplay ul {
    display: flex;
    margin: -0.25em;
    font-size: 0.8em;
}

#searchDisplay li {
    margin: 0.25em;
}

#searchDisplay li i {
    display: flex;
    align-items: center;
}

#searchDisplay a {
    display: flex;
    align-items: center;
    grid-gap: 0.3em;
    padding: 0.5em;
    background: #fff;
    border: 1px solid #7f8788;
    border-radius: 5px;
    color: inherit;
    fill: #7f8788;
    text-decoration: none;
    cursor: pointer;
}

#searchDisplay .select {
    background: #0080d2;
    border-color: transparent;
    color: #fff;
    fill: currentColor;
    pointer-events: none;
}

#pagenation {
    padding-top: 0;
}

.searchMenuBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #ccc;
    z-index: 10;
}

.searchMenuBox>div {
    overflow-y: scroll;
    padding-right: 2em !important;
}

.searchMenuBox .close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    width: 1.5em;
    height: 1.5em;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.searchMenuBox .close svg {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
}

.spotGrid,
.spotList,
.articleGri,
.articleList {}

.spotGrid dt+dd,
.spotList dt+dd,
.articleGrid dt+dd,
.articleList dt+dd {
    position: relative;
}

.spotGrid dt+dd i,
.spotList dt+dd i,
.articleGrid dt+dd i,
.articleList dt+dd i {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    width: 4em;
    height: 4em;
    z-index: 1;
}

.spotGrid dt+dd svg,
.spotList dt+dd svg,
.articleGrid dt+dd svg,
.articleList dt+dd svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spotList,
.articleList {}

.spotList dl,
.articleList dl {
    border-bottom: 1px solid #d1d1d1;
}

.spotGrid,
.spotList {}

.spotGrid dt+dd,
.spotList dt+dd {}

.spotGrid dt+dd i,
.spotList dt+dd i {
    left: -0.2em;
    top: -0.2em;
    width: 8em;
    height: 8em;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
}

.spotGrid dt+dd svg,
.spotList dt+dd svg {
    left: -1em;
    top: -1em;
}

.spotGrid {
    display: flex;
    flex-wrap: wrap;
}

.spotGrid [lsc-lt="dummy"] {
    display: inherit;
    flex-wrap: inherit;
    width: 100%;
    margin-top: 4em;
}

.spotList {}

.spotList [lsc-lt="dummy"] {
    display: block;
    margin-top: 4em;
}

.articleGrid {
    display: flex;
    flex-wrap: wrap;
}

.articleGrid [lsc-lt="dummy"] {
    display: inherit;
    flex-wrap: inherit;
    width: 100%;
    margin-top: 4em;
}

.articleList {}

.articleList [lsc-lt="dummy"] {
    display: block;
    margin-top: 4em;
}

.slick-arrow {
    width: 2em;
    height: 4em;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 2em;
    fill: #fff;
    cursor: pointer;
    z-index: 1;
}

.slick-arrow svg {
    position: absolute;
    top: 30%;
    left: 30%;
    width: 40%;
    height: 40%;
}




/* for all */
@media (max-width: 1199.98px) {
    /*1199px以下*/
}

@media (max-width: 991.98px) {

    /*991px以下*/
    #searchMenuSelect {}

    #searchMenu {}

    #searchMenu .wrap {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    #searchMenuSelect {
        order: 1;
    }

    #searchMenuSelect ul {
        flex-direction: column;
    }

    #searchMenuSelect li {}

    #searchMenuSelect li:not(:last-child) {
        height: 3em;
    }

    #searchMenuSelect li:last-child {
        flex-direction: column;
    }

    #searchMenuSelect li+li {
        margin-top: 1em;
    }

    #searchMenuSelect button {
        width: 10em;
        margin-top: 0.5em;
        padding: 0.8em;
        border-radius: 2em;
    }

    #searchMenuSelect button:after {
        content: "検索する";
        margin-left: 1em;
    }

    #searchMenuDetail {
        margin-top: 1em;
    }

    .searchMenuBox {
        top: 4em;
        margin-top: -0.5em;
    }

    .searchMenuBox:nth-of-type(2) {
        top: 8em;
    }

    .searchMenuBox:nth-of-type(3) {
        top: 12em;
    }

    .searchMenuBox:nth-of-type(4) {
        top: 16em;
    }

    .searchMenuBox:nth-of-type(5) {
        top: 20em;
    }
}


/* for mobile */
@media (max-width: 767.98px) {

    /*767以下px*/
    #theme {}

    #theme section {}

    #theme section .wrap {
        display: flex;
        flex-direction: column;
    }

    #theme figure {
        margin: 0 -1.5em;
        margin-top: 1em
    }

    #searchSetting {
        padding: 1em 0;
    }

    #pagenation {
        margin-top: 2em;
    }

    .searchMenuBox {}

    .searchMenuBox>div {
        max-height: 80vh;
        padding: 1em;
    }
}

@media (max-width: 767.98px) and (orientation:portrait) {

    /*767px以下 縦向き*/
    #searchMenuDetail {}

    #searchMenuDetail ul {
        flex-direction: column;
    }

    #searchMenuDetail button {
        width: 100%;
    }

    #searchMenuArea {}

    #searchMenuArea figure+div {
        margin: 1em 0 2em 0;
    }

    #searchMenuDate {}

    #searchMenuDate .slick-list {
        width: calc(100% - 6em);
    }

    #searchMenuDate .slick-arrow {
        transform: scale(0.8);
    }

    #searchSetting {}

    #searchSetting .wrap {
        flex-direction: column;
        grid-gap: 1em;
    }

    .spotList,
    .articleList {}

    .spotList dl,
    .articleList dl {
        padding-bottom: 1em;
    }

    .spotList dl+dl,
    .articleList dl+dl {
        margin-top: 1em;
    }

    .spotGrid {}

    .spotGrid dl {
        width: calc(50% - 0.5em);
    }

    .spotGrid dl:nth-of-type(n+3) {
        margin-top: 2em;
    }

    .spotGrid dl:nth-of-type(even) {
        margin-left: auto;
    }

    .articleGrid {}

    .articleGrid dl {
        width: calc(50% - 0.5em);
    }

    .articleGrid dl:nth-of-type(n+3) {
        margin-top: 2em;
    }

    .articleGrid dl:nth-of-type(even) {
        margin-left: auto;
    }
}

@media (max-width: 767.98px) and (orientation:landscape) {

    /*767px以下 横向き*/
    #searchMenuDetail {}

    #searchMenuDetail ul {
        flex-wrap: wrap;
    }

    #searchMenuDetail li {
        width: calc(50% - 1em);
    }

    #searchMenuDetail li:nth-of-type(even) {
        margin-left: 2em;
    }
}

@media (max-width: 767.98px) and (orientation:landscape),
(min-width: 768px) {

    /*767px以下 横向き, 768px以上,*/
    #searchMenuDetail {}

    #searchMenuDetail button {
        width: 16em;
    }

    #searchMenuArea {}

    #searchMenuArea>div {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    #searchMenuArea figure {
        width: calc(50% - 1em);
    }

    #searchMenuArea figure+div {
        width: calc(50% - 1em);
    }

    #searchMenuArea figure+div ul {
        flex-direction: column;
        padding-left: 2em;
    }

    #searchMenuArea figure+div li {
        width: 100%;
        margin-left: 0 !important;
    }

    #searchMenuArea figure+div+div {
        width: 100%;
        margin-top: 2em;
    }

    #searchMenuDate {}

    #searchMenuDate .slick-list {
        width: calc(100% - 8em);
    }

    #searchMenuDate .slick-slide {
        padding: 0 0.5em;
    }

    #searchSetting {}

    #searchSetting .wrap {
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        grid-gap: 1em;
    }
}

@media (max-width: 767.98px) and (orientation:landscape),
(min-width: 768px) and (max-width: 991.98px) {

    /*767px以下 横向き, 768px～991px*/
    #searchMenuArea {}

    #searchMenuArea figure,
    #searchMenuArea figure+ul {
        width: calc(50% - 0.5em);
    }

    #searchCount {
        width: 100%;
        margin-bottom: 1em;
    }

    .spotList,
    .articleList {}

    .spotList dl,
    .articleList dl {
        padding-bottom: 1.5em;
    }

    .spotList dl+dl,
    .articleList dl+dl {
        margin-top: 1.5em;
    }

    .spotGrid {}

    .spotGrid dl {
        width: calc(33.33% - 1.33em);
    }

    .spotGrid dl:nth-of-type(3n+2) {
        margin-left: 2em;
        margin-right: auto;
    }

    .spotGrid dl:nth-of-type(n+4) {
        margin-top: 2em;
    }

    .articleGrid {}

    .articleGrid dl {
        width: calc(33.33% - 1.33em);
    }

    .articleGrid dl:nth-of-type(3n+2) {
        margin-left: 2em;
        margin-right: auto;
    }

    .articleGrid dl:nth-of-type(n+4) {
        margin-top: 2em;
    }
}


/* for tablet */
@media (min-width: 768px) {

    /*768px以上*/
    #theme {}

    #theme section {}

    #theme section .wrap {
        display: -ms-grid;
        -ms-grid-columns: calc(50% - 1em) 2em calc(50% - 1em);
        -ms-grid-rows: auto auto 0px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 2em;
    }

    #theme h3 {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
    }

    #theme h3+div {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
        margin-top: 1em;
    }

    #theme h4+div {
        margin-top: 1em;
    }

    #theme figure {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        -ms-grid-row-span: 4;
        grid-row: 1 / 4;
    }

    #searchMenuDetail {}

    #searchMenuDetail ul {
        flex-wrap: wrap;
    }

    #searchSetting {
        padding: 2em 0;
    }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
    /*768px～1199px*/
}

@media (min-width: 768px) and (max-width: 991.98px) {

    /*768px～991px*/
    #theme {}

    #theme section .wrap {
        grid-gap: 0 3em;
    }

    #searchMenuDetail {}

    #searchMenuDetail li {
        width: calc(33.33% - 1.33em);
    }

    #searchMenuDetail li:nth-of-type(3n+2) {
        margin-left: 2em;
        margin-right: auto;
    }

    #pagenation {
        margin-top: 3em;
    }

    .searchMenuBox {}

    .searchMenuBox>div {
        max-height: 50vh;
        padding: 1.5em;
    }
}


/* for PC */
@media (min-width: 992px) {

    /*992px以上*/
    #feature+#search #searchMenuSelect li:nth-of-type(3),
    #course+#search #searchMenuSelect li:nth-of-type(3),
    #spot+#search #searchMenuSelect li:nth-of-type(3),
    #experience+#search #searchMenuSelect li:nth-of-type(3),
    #event+#search #searchMenuSelect li:nth-of-type(4),
    #stay+#search #searchMenuSelect li:nth-of-type(3) {
        min-width: 14em;
    }

    #theme {}

    #theme section {}

    #theme section .wrap {
        grid-gap: 0 4em;
    }

    #searchMenuSelect {
        margin-top: 1em;
    }

    #searchMenuSelect li {
        width: 50%;
    }

    #searchMenuSelect li:last-child {
        min-width: 15em;
    }

    #searchMenuSelect li+li {
        margin-left: 1em;
    }

    #searchMenuSelect button {
        width: 2.6em;
        height: 2.6em;
        border-radius: 100%;
        margin-left: 0.5em;
    }

    #searchMenuDetail {}

    #searchMenuDetail>div {
        margin-top: 1em;
    }

    #searchMenuDetail li {
        width: calc(25% - 1.5em);
    }

    #searchMenuDetail li:not(:nth-of-type(4n+1)) {
        margin-left: 2em;
    }

    #pagenation {
        margin-top: 4em;
    }

    .searchMenuBox {}

    .searchMenuBox>div {
        max-height: 50vh;
        padding: 2em;
    }

    .spotList,
    .articleList {}

    .spotList dl,
    .articleList dl {
        padding-bottom: 2em;
    }

    .spotList dl+dl,
    .articleList dl+dl {
        margin-top: 2em;
    }

    .spotGrid {}

    .spotGrid dl {
        width: calc(25% - 1.5em);
    }

    .spotGrid dl:not(:nth-of-type(4n+1)) {
        margin-left: 2em;
    }

    .spotGrid dl:nth-of-type(n+5) {
        margin-top: 2em;
    }

    .articleGrid {}

    .articleGrid dl {
        width: calc(25% - 1.5em);
    }

    .articleGrid dl:not(:nth-of-type(4n+1)) {
        margin-left: 2em;
    }

    .articleGrid dl:nth-of-type(n+5) {
        margin-top: 2em;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    /*992px～1199px*/
}

@media (min-width: 1200px) {
    /*1200px以上*/
}

.carousel .thumb {
    height: 60px;
    object-fit: cover;
}
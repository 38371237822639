.infoBox {
  .image {
    text-align: center;

    img {
      width: 200px;
      height: 145px;
      object-fit: cover;
    }
  }

  .name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }

  .address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
}
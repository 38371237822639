@import "../../assets/styles/variables";

.cart {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 20;

  .cartWrapper {
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    padding: 1em;
    width: 400px;
    max-height: 500px;
    overflow: auto;

    @include breakpoint(untilTablet) {
      width: 90vw;
      position: absolute;
      left: -250%;
      transform: translateX(-50%);
      bottom: 2.5rem;
    }

    .items {
      min-height: 300px;
      padding: 0;
    }

    .item {
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      height: fit-content;

      position: relative;

      .image {
        width: 30%;
        padding-top: 0;
        margin-right: 1rem;
        height: 70px;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .info {
        margin: 0;
        height: auto;
        width: 70%;
        padding-right: 2rem;

        .title {
          font-weight: bold;
          overflow: hidden;
          font-size: 1.1em;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: .25em;
          background: none;
          padding: 0;
          margin: 0;
        }

        .date,
        .qty,
        .price {
          font-size: 0.9em;
          margin-top: 2px;
        }
      }

      .remove {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
      }
    }

    .image {
      .imageContainer {
        padding-top: 100%;
        position: relative;

        img {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: contain;
          width: 100%;
        }
      }
    }

    .remove {
      cursor: pointer;
    }
  }

  .cartButton {
    display: inline-block;
    position: relative;
    background: $primary;
    padding: 0.5rem;
    border-radius: 50%;
    font-size: 1.6em;
    height: 50px;
    width: 50px;
    text-align: center;
    box-shadow: 0 0 5px 5px rgba($color: white, $alpha: 0.2);
    color: white;
    transition: .2s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    .cartTotal {
      position: absolute;
      font-size: 0.45em;
      top: -4px;
      right: -4px;
      height: 1.5em;
      width: 1.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 50%;
      background-color: red;
      color: white;
    }

    &:hover {
      background-color: lighten($color: $primary, $amount: 5%);
    }

    @include breakpoint(untilTablet) {
      .cartTotal {
        font-size: .5em;
        width: 1.25em;
        height: 1.25em;
        right: -.25em;
        top: -.5rem;
      }
    }
  }
}